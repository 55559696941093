<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
<!--        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.glass_glasscodeuse_delete"
                @click="handleDelete">删 除
        </el-button>-->
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button size="small" type="text" @click="resetUser(scope.row)">
          还原
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { reset } from '@/api/glass/glasscodeuse'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glasscodeuse',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '完整防伪码',
            prop: 'allCode',
            search: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '完整防伪码'
            }]
          },
          {
            label: '姓氏',
            prop: 'nickName',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '用户',
            prop: 'userId',
            search: true,
            type: 'select',
            allowCreate: true,
            filterable: true,
            dicUrl: 'app/user/all',
            addDisplay: false,
            editDisplay: false,
            props: {
              value: 'id',
              label: 'mobile'
            }
          },
          {
            label: '使用日期',
            prop: 'createTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '兑换日期',
            prop: 'getTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '联系方式',
            prop: 'phone',
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入联系方式'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            search: true,
            type: 'select',
            dicUrl: '/sys/dict/code/use_status'
          },
          {
            label: '地址记录',
            prop: 'address',
            type: 'textarea',
            span: 24,
            rules: [{
              required: true,
              message: '记录地址'
            }]
          },
          {
            label: '快递记录',
            prop: 'delivery',
            type: 'textarea',
            span: 24,
            rules: [{
              required: true,
              message: '记录地址'
            }]
          },
          {
            label: '顾客要求联系时间',
            width: 200,
            prop: 'updateTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glasscodeuse_add,
        viewBtn: this.permission.glass_glasscodeuse_view,
        delBtn: this.permission.glass_glasscodeuse_delete,
        editBtn: this.permission.glass_glasscodeuse_edit
      }
    }
  },
  methods: {
    resetUser (row) {
      console.log(row)
      this.$confirm('确定将选择记录还原?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return reset(row.id)
      }).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
