<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.glass_glasscode_delete"
                @click="handleDelete">删 除
        </el-button>
        <el-button type="success"   @click.stop="handleExport()">导出</el-button>
        <el-upload
            class="filter-item"
            :show-file-list="false"
            :action="handleImport()"
            :headers="uploadHeaders()"
            :on-success="uploadSuc">
          <el-button type="warning" icon="upload">导入</el-button>
        </el-upload>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'glass/glasscode',
      s: '',
      n: '',
      c: '',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '系列代码',
            prop: 'seriesName',
            type: 'select',
            dicUrl: 'glass/glassseries/all',
            props: {
              value: 'name',
              label: 'remark'
            },
            change: ({ value, column }) => {
              this.s = value
              const ac = this.s + this.n + this.c
              this.form.allCode = ac
            }
          },
          {
            label: '兑换用户',
            prop: 'userId',
            dicUrl: 'app/user/all',
            addDisplay: false,
            editDisplay: false,
            props: {
              value: 'id',
              label: 'mobile'
            }
          },
          {
            label: '渠道代码',
            prop: 'channel',
            type: 'select',
            dicUrl: '/glass/glasschannel/list',
            props: {
              value: 'code',
              label: 'name'
            },
            change: ({ value, column }) => {
              this.n = value
              const ac = this.s + this.n + this.c
              this.form.allCode = ac
            }
          },
          {
            label: '数字码',
            prop: 'codeNo',
            type: 'number',
            minRows: 10000,
            maxRows: 9999999999,
            search: true,
            controls: false,
            rules: [{
              required: true,
              message: '数字码'
            }],
            change: ({ value, column }) => {
              this.c = value
              const ac = this.s + this.n + this.c
              this.form.allCode = ac
            }
          },
          {
            label: '完整防伪码',
            prop: 'allCode',
            addDisabled: true,
            editDisabled: true,
            span: 24
          },
          {
            label: '使用时间',
            prop: 'usedTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '领取时间',
            prop: 'updateTime',
            type: 'datetime',
            addDisplay: false,
            editDisplay: false,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: '查询状态',
            prop: 'queryStatus',
            search: true,
            type: 'select',
            addDisplay: false,
            editDisplay: false,
            dicUrl: '/sys/dict/code/query_status'
          },
          {
            label: '兑换状态',
            prop: 'status',
            search: true,
            type: 'select',
            addDisplay: false,
            editDisplay: false,
            dicUrl: '/sys/dict/code/code_status'
          },
          {
            label: '权益状态',
            prop: 'legalStatus',
            search: true,
            type: 'select',
            addDisplay: false,
            editDisplay: false,
            dicUrl: '/sys/dict/code/legal_status'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.glass_glasscode_add,
        viewBtn: this.permission.glass_glasscode_view,
        delBtn: this.permission.glass_glasscode_delete,
        editBtn: this.permission.glass_glasscode_edit
      }
    }
  },
  methods: {
  }
}
</script>
